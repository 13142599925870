import React from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  useTheme,
  useMediaQuery,
} from '@mui/material';

interface MenuItemProps {
  id: string;
  name: string;
  ingredients?: string;
  price: string;
}

const MenuItem: React.FC<MenuItemProps> = ({
  id,
  name,
  ingredients,
  price,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card
      variant="outlined"
      sx={{
        fontSize: isMobile ? '0.7rem' : '1rem',
        backgroundColor: '#E4CBA2',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <CardContent
        sx={{
          flexGrow: 1,
          '&:last-child': {
            paddingBottom: 0,
          },
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          sx={{ height: '100%' }}
        >
          <Grid item>
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Typography
                variant="h6"
                sx={{
                  color: '#A52A2A',
                  fontWeight: 'bold',
                  fontSize: isMobile ? '1rem' : '1.3rem',
                  mr: 1,
                  fontFamily: 'Oswald',
                }}
              >
                {id}.
              </Typography>
              <Box sx={{ textAlign: 'left' }}>
                <Typography
                  variant="h6"
                  sx={{
                    color: 'black',
                    fontSize: isMobile ? '1rem' : '1.3rem',
                    fontFamily: 'Oswald',
                  }}
                >
                  {name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: isMobile ? '0.8rem' : '0.9rem',
                    color: ingredients ? 'black' : 'transparent',
                  }}
                >
                  {ingredients || 'test'}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              align="right"
              sx={{
                fontSize: isMobile ? '1rem' : '1.3rem',
                fontWeight: 'bold',
                color: '#642925',
                fontFamily: 'Oswald',
              }}
            >
              {price}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MenuItem;
