import React from 'react';
import { Typography, Box, Paper, useTheme, useMediaQuery } from '@mui/material';
import aboutImage from '../assets/backgroundImages/homeHero6.png';
import Footer from '../components/Footer';

const About = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const textStyles = {
    fontFamily: 'Oswald',
    fontSize: isMobile ? '1.1rem' : '1.5rem',
    lineHeight: 2,
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: isMobile ? '#F9E0B1' : 'transparent',
        backgroundImage: isMobile ? 'none' : `url(${aboutImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        border: isMobile ? '0.5rem solid #E5CBA2' : 'transparent',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper
          elevation={isMobile ? 0 : 3}
          sx={{
            width: '100%', // Adjust as needed
            margin: isMobile
              ? 0
              : {
                  xs: '2rem 1rem',
                  sm: '2rem 2rem',
                  md: '2rem 15rem',
                  lg: '2rem 17rem',
                },
            padding: isMobile ? '2rem' : '3rem',
            backgroundColor: isMobile ? 'transparent' : '#F9E0B1',
            border: isMobile ? 'transparent' : '10px solid #E5CBA2',
            borderRadius: isMobile ? 0 : '8px',
            overflow: 'hidden',
            height: '80vh',
          }}
        >
          <Typography sx={textStyles}>
            Impressum Angaben gemäß § 5 TMG: Kontakt: Telefon: 02441 / 777274
          </Typography>
          <Typography sx={textStyles}>Quoc Tuan Trieu</Typography>
          <Typography sx={textStyles}>China Thai-Restaurant Zhao</Typography>
          <Typography sx={textStyles}>Kölner Str. 34 53925 Kall</Typography>
          <Typography sx={textStyles}>
            Internet: www.chinazhaokall.de
          </Typography>
          <Typography sx={textStyles}>
            Umsatzsteuer-ID: Umsatzsteuer-Identifikationsnummer gemäß §27 a
            Umsatzsteuergesetz: 211/5065/1483
          </Typography>
        </Paper>
      </Box>
      <Box sx={{ mt: 'auto', alignSelf: 'flex-start', width: '100%' }}>
        <Footer></Footer>
      </Box>
    </Box>
  );
};

export default About;
