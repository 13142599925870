import React from 'react';
import { Typography, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material';

const AboutSection: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const fontSize = isMobile ? '1.1rem' : '1.2rem';

  return (
    <Box sx={{ width: '100%', marginTop: isMobile ? '1rem' : '15rem' }}>
      {/* Address */}
      <Typography
        variant="h5"
        sx={{
          fontSize: fontSize,
          fontFamily: 'Oswald',
          color: 'white',
          textShadow: '3px 3px 4px rgba(22, 22, 22, 0.4)',
        }}
      >
        Kölnerstraße 34, 53925 Kall
      </Typography>

      {/* Phone Number */}
      <Typography
        variant="h5"
        sx={{
          fontSize: fontSize,
          fontFamily: 'Oswald',
          color: 'white',
          marginTop: '1rem',
          textShadow: '3px 3px 4px rgba(22, 22, 22, 0.4)',
        }}
      >
        Telefon: 02441 777274
      </Typography>

      {/* Opening Hours */}
      <Typography
        variant="h5"
        sx={{
          fontSize: fontSize,
          fontFamily: 'Oswald',
          color: 'white',
          marginTop: '1rem',
          textShadow: '3px 3px 4px rgba(22, 22, 22, 0.4)',
        }}
      >
        Öffnungszeiten: Donnerstag - Sonntag
      </Typography>
      <Typography
        variant="h5"
        sx={{
          fontSize: fontSize,
          fontFamily: 'Oswald',
          color: 'white',
          marginTop: '1rem',
          textShadow: '3px 3px 4px rgba(22, 22, 22, 0.4)',
        }}
      >
        11:30 bis 14:30 und 17:30 bis 21:00
      </Typography>
    </Box>
  );
};

export default AboutSection;
