import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  List,
  ListItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // Import the burger menu icon
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Popover from '@mui/material/Popover';

const Navbar: React.FC = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const popoverStyles = {
    '& .MuiList-root': {
      padding: '1rem', // Padding around the list
      backgroundColor: '#F8E0B1',
    },
    '& .MuiListItem-root': {
      justifyContent: 'center', // Center list items
    },
    '& .MuiButton-root': {
      fontFamily: 'Oswald',
      fontSize: isMobile ? '1.5rem' : '2.5rem', // Responsive font size
      color: 'black',
      width: '20rem', // Make the button fill the list item
      backgroundColor: '#E4CBA2',
      justifyContent: 'center', // Center list items
      borderRadius: '8px', // Remove the border radius
      height: '4rem',
    },
  };

  const location = useLocation();
  const onHomePage = location.pathname === '/';

  const navigationLinks = [
    { name: 'Startseite', path: '' },
    { name: 'Speisekarte', path: 'menu' },
    { name: 'Impressum', path: 'about' },
  ];

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <AppBar
      position="fixed"
      sx={{ background: 'transparent', boxShadow: 'none', width: '3rem' }}
    >
      <Toolbar sx={{ justifyContent: 'flex-end' }}>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuClick}
          sx={{
            marginRight: isMobile ? 0 : '3rem',
            '& .MuiSvgIcon-root': {
              fontSize: '4rem',
              color: onHomePage ? 'white' : 'white',
            },
            pt: isMobile ? '2rem' : '4rem',
            '&:hover, &:focus': {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
            '&:hover': {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          <MenuIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right', // Anchor to the right edge of the MenuIcon
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right', // Transform from the right edge
          }}
          sx={popoverStyles}
        >
          <List>
            {navigationLinks.map((link) => (
              <ListItem
                key={link.name}
                button
                component={Link}
                to={`/${link.path}`}
                onClick={handleMenuClose}
              >
                <Button
                  sx={{
                    fontFamily: 'Oswald',
                    fontSize: isMobile ? '2rem' : '1rem',
                    color: 'black',
                  }}
                >
                  {link.name}
                </Button>
              </ListItem>
            ))}
          </List>
        </Popover>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
