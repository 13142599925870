const vorspeisen = [
  { id: "15", name: "Loempia (Frühlingsrolle)", ingredients: "", price: "4,30 €" },
  { id: "16", name: "Vegetarische Loempia (Frühlingsrolle)", ingredients: "", price: "4,30 €" },
  { id: "17", name: "Mini-Frühlingsrollen (8 Stück)", ingredients: "", price: "4,30 €" },
  { id: "18", name: "Gebackene Wan Tan (4 Stück)", ingredients: "", price: "5,00 €" },
  { id: "19", name: "Gebackene Tintenfischringe", ingredients: "", price: "5,00 €" },
  { id: "24", name: "Kroepoek (Krabbenbrot)", ingredients: "", price: "2,50 €"}
];

export default vorspeisen;
