import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer: React.FC = () => {
  return (
    <Box
      sx={{
        color: '#fff',
        margin: '0.5rem',
      }}
    >
      <Typography variant="body2" sx={{ fontFamily: 'Oswald' }}>
        © 2023 China Restaurant
      </Typography>
    </Box>
  );
};

export default Footer;
