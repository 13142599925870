const beilagen = [
  { id: "", name: "Gekochter Reis (Extra Portion)", ingredients: "", price: "2,00 €" },
  { id: "", name: "Gebratene Nudeln", ingredients: "", price: "4,00 €" },
  { id: "", name: "Gebratenener Reis", ingredients: "", price: "4,00 €" },
  { id: "", name: "Pommes Frites", ingredients: "", price: "4,00 €" },
  { id: "", name: "Curry-Soße (g)", ingredients: "", price: "3,00 €" },
  { id: "", name: "Süß-sauer Soße (1)", ingredients: "", price: "3,00 €" },
  { id: "", name: "Erdnuss-Soße (e)", ingredients: "", price: "3,00 €" },
  { id: "", name: "scharfe Knoblauch Soße", ingredients: "", price: "3,00 €" }
];

export default beilagen;
