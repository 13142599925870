const vegetarische = [
  { id: "35", name: "Gemischtes Gemüse mit pikanter Soße (scharf/ nicht scharf) (3)", ingredients: "", price: "11,50 €" },
  { id: "36", name: "Gebratener Tofu mit verschiedenem Gemüse (3)", ingredients: "", price: "11,50 €" },
  { id: "37", name: "Gebratener Tofu mit verschiedenem Gemüse (scharf) (3)", ingredients: "", price: "11,50 €" },
  { id: "39", name: "Gebratenes Gemüse mit Ananas in süß-saurer Soße (3)", ingredients: "", price: "11,50 €" },
  { id: "43", name: "Gebratenes Gemüse in rotem Thai-Curry mit Kokosmilch", ingredients: "", price: "12,00 €" },
  { id: "43a", name: "Gebratener Tofu in rotem Thai-Curry mit Kokosmilch", ingredients: "mit versch. Gemüse", price: "12,50 €" }
];

export default vegetarische;
