const gebrateneNudeln = [
  { id: "93", name: "Gebratene Nudeln mit versch. Gemüse (3)", ingredients: "", price: "11,50 €" },
  { id: "93a", name: "Gebratene Nudeln mit grösteter Ente", ingredients: "", price: "15,90 €" },
  { id: "94", name: "Gebratene Nudeln mit Rindfleisch (3)", ingredients: "", price: "12,00 €" },
  { id: "96", name: "Gebratene Nudeln mit Hühnerfleisch (natur) (3)", ingredients: "", price: "12,00 €" },
  { id: "97", name: "Gebratene Nudeln mit knusprigen Hühnerfilet (3,a)", ingredients: " in Teig, ", price: "12,00 €" },
  { id: "98", name: "Gebratene Nudeln mit großen Hummerkrabben (3)", ingredients: "", price: "19,00 €" },
  { id: "99", name: "Bami-Goreng (g,m)", ingredients: "gebratene Nudeln mit Hühnerfleisch, Schinken, Shrimps in Curry", price: "13,00 €" },
];

export default gebrateneNudeln;
