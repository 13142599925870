const rindfleisch = [
  { id: "44", name: "Rindfleisch Chop-Suey (versch. gemüse) (d)", ingredients: "", price: "12,50 €" },
  { id: "45", name: "Rindfleisch nach Szechuan-Art ", ingredients: "(mittel-scharf)", price: "12,50 €" },
  { id: "46", name: "Rindfleisch mit Ananas in scharfer süß-saurer Soße (1)", ingredients: "", price: "12,50 €" },
  { id: "49", name: "Rindfleisch mit Zwiebeln ", ingredients: "(ohne Gemüse)", price: "12,50 €" },
  { id: "50", name: "Rindfleisch in Satey-Soße (3,e)", ingredients: "(Erdnusssoße)", price: "12,50 €" },
  { id: "51", name: "Rindfleisch mit Brokkoli in scharfer Knoblauch-Soße (3)", ingredients: "", price: "12,50 €" },
  { id: "52", name: "Gaeng Kaew Wan Nuea (sehr scharf) (g)", ingredients: "Rindfleisch in grünem Thai-Curry mit Kokosmilch, Auberginen und Zucchini", price: "13,50 €" },
  { id: "53", name: "Gaeng Nuea (mittel scharf)", ingredients: "Rindfleisch mit Auberginen und Zucchini in rotem Thai-Curry und Kokosmilch ", price: "13,50 €" }
];

export default rindfleisch;
