const ente = [
  { id: "104", name: "Knusprige Ente Chop-Suey", ingredients: "", price: "16,90 €" },
  { id: "105", name: "Knusprige Ente nach Szechuan-Art (scharf) (3)", ingredients: "", price: "16,90 €" },
  { id: "106", name: "Knusprige Ente in süß-saurer Soße", ingredients: "mit Sojasprossen", price: "16,90 €" },
  { id: "107", name: "Knusprige Ente in scharfer Knoblauch-Soße", ingredients: "", price: "16,90 €" },
  { id: "109", name: "Knusprige Ente in Satey-Soße (Erdnusssoße)", ingredients: "", price: "16,90 €" },
  { id: "110", name: "Kur Pet (sehr scharf) (g)", ingredients: "Knusp. Ente in rotem Thai-Curry mit Kokosmilch mit Ananas und Lychees", price: "17,50 €" },
  { id: "112", name: "Acht Kostbarkeiten (3)", ingredients: "Entenfilet, Hühnerfleisch, Rind mit scharfer Soße", price: "18,00 €" }
];

export default ente;
