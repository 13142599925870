import React from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';

interface DeliveryInfoTableProps {
  onClose: () => void;
}

const DeliveryInfoTable: React.FC<DeliveryInfoTableProps> = ({ onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const cellStyle = {
    color: isMobile ? '#5f4209' : 'white',
    fontFamily: 'Oswald',
    fontSize: isMobile ? '1rem' : '1.2rem',
    borderBottom: `1px solid ${isMobile ? '#5f4209' : 'white'}`,
    padding: isMobile ? '8px' : '16px',
  };

  return (
    <Box
      sx={{
        border: isMobile ? '0.5rem solid #E5CBA2' : 'none',
        height: '100%',
      }}
    >
      <TableContainer
        sx={{
          maxWidth: '100%',
          backgroundColor: 'transparent',
          boxShadow: 'none',
        }}
      >
        <Table
          aria-label="delivery info table"
          size={isMobile ? 'small' : 'medium'}
        >
          <TableHead>
            <TableRow>
              <TableCell sx={cellStyle}>Liefergebiete</TableCell>
              <TableCell align="right" sx={cellStyle}>
                Mindestbestellung
              </TableCell>
              <TableCell align="right" sx={cellStyle}>
                Fahrtkosten
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={cellStyle}>Weniger als 3 km</TableCell>
              <TableCell align="right" sx={cellStyle}>
                ab 15,00€
              </TableCell>
              <TableCell align="right" sx={cellStyle}>
                frei Haus
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={cellStyle}>ab 3 km bis 4 km</TableCell>
              <TableCell align="right" sx={cellStyle}>
                ab 20,00€
              </TableCell>
              <TableCell align="right" sx={cellStyle}>
                +1,00€
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={cellStyle}>ab 5 bis 6 km</TableCell>
              <TableCell align="right" sx={cellStyle}>
                ab 25,00€
              </TableCell>
              <TableCell align="right" sx={cellStyle}>
                +2,00€
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={cellStyle}>ab 6 bis 8 km</TableCell>
              <TableCell align="right" sx={cellStyle}>
                ab 40,00€
              </TableCell>
              <TableCell align="right" sx={cellStyle}>
                +3,00€
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {isMobile && (
          <Box
            sx={{
              textAlign: 'center',
              mt: 3,
            }}
          >
            <Button
              onClick={onClose}
              sx={{
                fontFamily: 'Oswald',
                fontSize: '1.1rem',
                color: '#5f4209',
                zIndex: 2,
                textShadow: '3px 3px 4px rgba(22, 22, 22, 0.4)',
              }}
            >
              Liefergebiete und Kosten ausblenden
            </Button>
          </Box>
        )}
      </TableContainer>
    </Box>
  );
};

export default DeliveryInfoTable;
