const gebratenerReis = [
  { id: "87", name: "Gebratener Reis mit Rindfleisch (3)", ingredients: "", price: "12,00 €" },
  { id: "88", name: "Gebratener Reis mit gerösteter Ente (3)", ingredients: "", price: "15,90 €" },
  { id: "89", name: "Gebratener Reis mit Hühnerfleisch (3)", ingredients: "", price: "12,00 €" },
  { id: "90", name: "Nasi-Goreng (9,m)", ingredients: "(Hühnerfleisch, Schinken, Shrimps) in Curry", price: "13,00 €" },
  { id: "91", name: "Gebratener Reis mit großen Hummerkrabben (3)", ingredients: "", price: "19,00 €" },
  { id: "92", name: "Gebratener Reis mit Gemüse", ingredients: "", price: "11,50 €" }
];

export default gebratenerReis;
