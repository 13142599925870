import mittagMenu from '../data/MittagMenu';
import suppen from '../data/Suppen';
import vorspeisen from '../data/Vorspeisen';
import vegetarische from '../data/Vegetarische';
import rindfleisch from '../data/Rindfleisch';
import huehnerfleisch from '../data/Huehnerfleisch';
import gebrateneNudeln from '../data/GebrateneNudeln';
import Ente from '../data/Ente';
import tintenfisch from '../data/Tintenfisch';
import curry from '../data/Curry';
import beilagen from '../data/Beilagen';
import nachtisch from '../data/Nachtisch';
import gebratenerReis from './GebratenerReis';
import hummerkrabben from './Hummerkrabben';

const mitReis = 'Alle Gerichte werden mit Reis und verschiedenem Gemüse serviert';

const menuData = [
  // M1 - M22
  { title: "Mittagsmenüs", description: "Donnerstag und Freitag von 11:30-14:30 Uhr (außer Feiertage)", items: mittagMenu },
  // 1 - 8
  { title: "Suppen", description: "", items: suppen },
  // 15 - 19
  { title: "Vorspeisen", description: "Alle Vorspeisen werden mit süß-sauer Soße serviert", items: vorspeisen },
  // 35 - 43a
  { title: "Vegetarische Gerichte", description: "mit Reis", items: vegetarische },
  // 44 - 53
  { title: "Rindfleisch Gerichte", description: mitReis, items: rindfleisch },
  // 70 - 81
  { title: "Hühnerfleisch Gerichte", description: mitReis, items: huehnerfleisch },
  // 87 - 92
  { title: "Gebratener Reis", description: "Alle Gerichte werden mit Zwiebeln, Möhren, Erbsen und Ei serviert", items: gebratenerReis },
  // 93 - 102
  { title: "Gebratene Nudeln", description: "Alle Gerichte werden mit Zwiebeln, Möhren, Sojasprossen und Ei serviert", items: gebrateneNudeln },
  // 104 - 112
  { title: "Ente Gerichte", description: mitReis, items: Ente },
  // 115 - 122
  { title: "Hummerkrabben Gerichte", description: mitReis, items: hummerkrabben },
  // F1 - F5
  { title: "Tintenfisch Gerichte", description: mitReis, items: tintenfisch },
  // C1 - C7
  { title: "Curry Gerichte", description: "Alle Gerichte werden mit Reis, verschidenem Gemüse und Kokosmilch serviert", items: curry },
  // Beilagen
  { title: "Beilagen", description: "Extra Beilagen und Soßen", items: beilagen },
  // Nachtisch
  { title: "Nachtisch", description: "", items: nachtisch }
];

export default menuData;