const huehnerfleisch = [
  { id: "70", name: "Hühnerfleisch Chop-Suey (3)", ingredients: "", price: "12,50 €" },
  { id: "71", name: "Hühnerfleisch mit Gemüse nach Szechuan-Art (3)", ingredients: " (mittel scharf)", price: "12,50 €" },
  { id: "72", name: "Gebr. Hühnerfleisch in scharfer süß-saurer Soße (1)", ingredients: "", price: "12,50 €" },
  { id: "74", name: "Hühnerfleisch in Satey-Soße (Erdnusssoße) (3,a)", ingredients: "", price: "12,50 €" },
  { id: "75", name: "Hühnerfleisch in scharfer Knoblauch-Soße (3)", ingredients: "", price: "12,50 €" },
  { id: "76", name: "Paniertes Hühnerfleisch in süß-saurer Soße (1)", ingredients: " mit Sojasprossen", price: "13,50 €" },
  { id: "77", name: "Paniertes Hühnerfleisch in spezial Soße (3,a)", ingredients: " (leicht scharf)", price: "13,50 €" },
  { id: "78", name: "Paniertes Hühnerfleisch in scharfer Knoblauch-Soße (3,a)", ingredients: "", price: "13,50 €" },
  { id: "79", name: "Knuspriges Hühnerfilet und Soße nach Wahl", ingredients: "", price: "13,50 €" },
  { id: "80", name: "Gaeng Keow Wan Gai (scharf/sehr scharf)", ingredients: "Hühnerfleisch mit Auberginen und Zucchini in grünem Thai-Curry und Kokosmilch", price: "13,90 €" },
  { id: "81", name: "Pa Naeng Gai (mittel scharf) (9,e)", ingredients: "Hühnerfleisch in rotem Thai-Curry mit Kokosmilch,Auberginen, Zucchini, Erdnüssen", price: "13,90 €" }
];

export default huehnerfleisch;
