import React, { useState } from 'react';
import { Box, Paper, Tabs, Tab, useTheme, useMediaQuery } from '@mui/material';
import MenuSection from '../components/MenuSection';
import menuData from '../data/MenuData';
import Footer from '../components/Footer';
import menuImage from '../assets/backgroundImages/homeHero6.png';

const Menu: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: isMobile ? '#F9E0B1' : 'transparent',
        backgroundImage: isMobile ? 'none' : `url(${menuImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {isMobile ? (
        <>
          {/* Mobile View Content */}
          {menuData.map(
            (menu, index) =>
              selectedTab === index && (
                <Box sx={{ paddingBottom: '4rem' }}>
                  <MenuSection
                    key={menu.title}
                    title={menu.title}
                    description={menu.description}
                    items={menu.items}
                  />
                </Box>
              )
          )}
          <Paper
            sx={{
              width: '100%',
              position: 'fixed',
              bottom: 0,
              left: 0,
              zIndex: theme.zIndex.appBar,
              borderTop: '1px solid rgba(0, 0, 0, 0.12)',
              backgroundColor: '#F9E0B1',
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              variant="scrollable"
              sx={{
                width: '100%',
                '.MuiTab-root': {
                  color: 'black',
                  fontSize: '0.75rem',
                  fontWeight: 'bold',
                  borderTop: '0.1rem solid #E4CBA2',
                  borderBottom: '0.1rem solid #E4CBA2',
                },
                '.Mui-selected': { color: '#A52A2A' },
                '.MuiTabs-indicator': {
                  backgroundColor: '#A52A2A',
                },
              }}
            >
              {menuData.map((menu, index) => (
                <Tab key={index} label={menu.title} />
              ))}
            </Tabs>
          </Paper>
        </>
      ) : (
        <>
          {/* Desktop View Content */}
          <Paper
            elevation={3}
            sx={{
              flexGrow: 1,
              margin: {
                xs: '2rem 1rem',
                sm: '2rem 2rem',
                md: '2rem 15rem',
                lg: '2rem 17rem',
              },
              padding: '3rem',
              backgroundColor: '#F9E0B1',
              border: '10px solid #E5CBA2',
              borderRadius: '8px',
              overflow: 'hidden',
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                '.MuiTab-root': {
                  color: 'black',
                  fontSize: '1rem',
                  fontFamily: 'Oswald',
                },
                '.MuiTabs-indicator': { backgroundColor: '#A52A2A' },
                '.Mui-selected': { color: '#A52A2A !important' },
              }}
            >
              {menuData.map((menu, index) => (
                <Tab key={index} label={menu.title} />
              ))}
            </Tabs>
            {menuData.map(
              (menu, index) =>
                selectedTab === index && (
                  <MenuSection
                    key={menu.title}
                    title={menu.title}
                    description={menu.description}
                    items={menu.items}
                  />
                )
            )}
          </Paper>
          <Footer />
        </>
      )}
    </Box>
  );
};

export default Menu;
