const mittagsMenus = [
  { id: "M1", name: "Gebr. Nudeln mit Hühnerfleisch (c,d)", ingredients: "Zwiebeln, Sojasprossen, Möhren, Ei", price: "8,50 €" },
  { id: "M3", name: "Gebr. Nudeln mit Rindfleisch (c,d)", ingredients: "Zwiebeln, Sojasprossen, Möhren, Ei", price: "8,50 €" },
  { id: "M4", name: "Gebr. Reis mit Hühnerfleisch (c)", ingredients: "Zwiebeln, Möhren, Ei, Erbsen", price: "8,50 €" },
  { id: "M6", name: "Gebr. Reis mit Rindfleisch (c)", ingredients: "Zwiebeln, Möhren, Ei, Erbsen", price: "8,50 €" },
  { id: "M8", name: "Rindfleisch Chop-Suey (versch. Gemüse) (d)", ingredients: "", price: "8,90 €" },
  { id: "M9", name: "Rindfleisch mit Gemüse nach Szechuan-Art (scharf)", ingredients: " ", price: "8,90 €" },
  { id: "M14", name: "Geb. Tintenfischringe in süß-saurer Soße (d)", ingredients: "mit Gemüse", price: "8,90 €" },
  { id: "M15", name: "Gebratener Tofu mit versch. Gemüse (d)", ingredients: "", price: "8,90 €" },
  { id: "M16", name: "Gebr. Hühnerfleisch Chop-suey (versch. Gemüse) (d)", ingredients: "", price: "8,90 €" },
  { id: "M17", name: "Hühnerfleisch mit Gemüse in Curry-Soße (d,g)", ingredients: "", price: "8,90 €" },
  { id: "M18", name: "Knuspriges Hühnerfilet mit Gemüse", ingredients: "Soße nach Wahl", price: "9,30 €" },
  { id: "M19", name: "Geröstete Ente mit Ananas und süß-saurer Soße (d)", ingredients: "", price: "12,00 €" },
  { id: "M20", name: "Geröstete Ente mit Gemüse und scharfer Soße (d)", ingredients: "", price: "12,00 €" },
  { id: "M22", name: "Gebr. Nudeln mit Ente und Gemüse (d)", ingredients: "", price: "12,00 €" }
];

export default mittagsMenus;