import React, { useState } from 'react';
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Drawer,
} from '@mui/material';
import heroImage from '../assets/backgroundImages/homeHero6.png';
import AboutSection from '../components/AboutSection';
import Footer from '../components/Footer';
import DeliveryInfoTable from '../components/DeliveryInfoTable';
import { useNavigate } from 'react-router-dom';

const Home: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [showDeliveryInfo, setShowDeliveryInfo] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleNavigate = () => {
    navigate('/menu');
  };

  const toggleDeliveryInfo = () => {
    setShowDeliveryInfo(!showDeliveryInfo);
  };

  const mobileView = (
    <>
      <Box
        sx={{
          display: isDrawerOpen ? 'flex' : 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pb: 4,
          zIndex: 2,
          mt: 12,
        }}
      >
        <AboutSection />
        <Button
          onClick={handleDrawerOpen}
          sx={{
            mt: 6,
            fontFamily: 'Oswald',
            fontSize: '1.1rem',
            zIndex: 2,
          }}
        >
          {showDeliveryInfo
            ? 'Liefergebiete und Kosten ausblenden'
            : 'Liefergebiete und Kosten anzeigen'}
        </Button>
        <Drawer
          anchor="bottom"
          open={isDrawerOpen}
          onClose={handleDrawerClose}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            '& .MuiDrawer-paper': {
              position: 'bottom',
              height: '40vh',
              backgroundColor: '#F9E0B1',
            },
            color: '#8B0000',
          }}
        >
          <DeliveryInfoTable onClose={handleDrawerClose} />
        </Drawer>
      </Box>
      <Footer />
    </>
  );

  const desktopView = (
    <>
      <Box
        sx={{
          position: 'absolute',
          left: '5rem',
          bottom: '3%',
          zIndex: 2,
          textAlign: 'left',
        }}
      >
        <AboutSection />
      </Box>
      <Button
        onClick={toggleDeliveryInfo}
        sx={{
          position: 'absolute',
          right: '5rem',
          bottom: '3%',
          zIndex: 2,
          fontFamily: 'Oswald',
          fontSize: '1.2rem',
        }}
      >
        {showDeliveryInfo
          ? 'Liefergebiete und Kosten ausblenden'
          : 'Liefergebiete und Kosten anzeigen'}
      </Button>
      {showDeliveryInfo && (
        <Box
          sx={{
            position: 'absolute',
            right: '15rem',
            bottom: '10%',
            zIndex: 2,
            backgroundColor: 'rgba(227, 203, 162, 0.7)',
            borderRadius: '10px',
            padding: '0.5rem',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
          }}
        >
          <DeliveryInfoTable onClose={handleDrawerClose} />
        </Box>
      )}
      <Footer />
    </>
  );

  return (
    <>
      <Box
        sx={{
          color: 'white',
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)',
          textAlign: 'center',
          maxWidth: '100%',
          p: isMobile ? 2 : 4,
          position: 'relative',
          backgroundImage: `url(${heroImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100vh',
          width: '100%',
          overflow: 'hidden',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: 1,
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: isMobile ? 'start' : 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant={isMobile ? 'h2' : 'h1'}
          sx={{
            fontSize: isMobile ? '8rem' : '10rem',
            mt: isMobile ? 10 : 2,
            fontFamily: 'Oswald',
            zIndex: 2,
            textShadow: '5px 5px 5px rgba(22, 22, 22, 0.8)',
          }}
        >
          Zhao
        </Typography>
        <Typography
          variant={isMobile ? 'h4' : 'h2'}
          sx={{
            fontSize: isMobile ? '2rem' : '3rem',
            fontFamily: 'Oswald',
            whiteSpace: 'nowrap',
            textShadow: '3px 3px 4px rgba(22, 22, 22, 0.4)',
            zIndex: 2,
          }}
        >
          China Thai Restaurant
        </Typography>
        <Button
          variant="contained"
          onClick={handleNavigate}
          sx={{
            mt: isMobile ? 3 : 6,
            backgroundColor: '#8B0000',
            color: 'white',
            '&:hover': {
              backgroundColor: '#640000',
            },
            fontSize: isMobile ? '1.7rem' : '2rem',
            fontWeight: 'bold',
            padding: '10px 20px',
            borderRadius: '4px',
            textTransform: 'none',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
            zIndex: 2,
          }}
        >
          Speisekarte
        </Button>

        {/* Conditional rendering based on screen size */}
        {isMobile ? mobileView : desktopView}
      </Box>
    </>
  );
};

export default Home;
