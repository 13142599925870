import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import MenuItem from './MenuItem';

interface MenuSectionProps {
  title: string;
  description: string;
  items: Array<{
    id: string;
    name: string;
    ingredients: string;
    price: string;
  }>;
}

const MenuSection: React.FC<MenuSectionProps> = ({
  title,
  description,
  items,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1200));

  return (
    <Box
      sx={{
        pt: isMobile ? '7rem' : '2rem',
        marginX: isMobile ? '2rem' : 0,
      }}
    >
      <Typography
        variant="h3"
        sx={{
          marginBottom: '1rem',
          color: 'brown',
          fontSize: isMobile ? '2rem' : '3rem',
          fontFamily: 'Oswald',
        }}
      >
        {title}
      </Typography>

      <Typography
        variant="body1"
        sx={{ marginBottom: '1rem', fontFamily: 'Oswald' }}
      >
        {description}
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: isSmallScreen ? '1fr' : 'repeat(2, 1fr)',
          gridAutoRows: '1fr',
          gap: '20px',
        }}
      >
        {items.map((item) => (
          <MenuItem
            id={item.id}
            name={item.name}
            ingredients={item.ingredients}
            price={item.price}
          />
        ))}
      </Box>
    </Box>
  );
};

export default MenuSection;
